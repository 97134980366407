@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');


@tailwind base;
@layer base {
    img {
      @apply inline-block;
    }
  }
@tailwind components;
@tailwind utilities;