.print-content {

    width: 8.5in;
    /* Ancho de la hoja carta en pulgadas */
    height: 60vh; /* Alto de la hoja carta en pulgadas */
    /* Alto de la hoja carta en pulgadas */
    margin: 0 auto;
    /* Centra el contenido en la hoja */
}

.img {
    width: 250px;
    height: 149px
}

@media print {
    body {
        font-size: 12px;
    }

    .print-content {
        width: 100%;
        /* Asegura que el contenido se ajuste al PDF */
    }

    /* Establece estilos específicos para la impresión aquí */
    /* ... Otros estilos para la impresión ... */
}